<template>
    <div class="container-fluid">
        <header class="jumbotron">
            <h3>5meter Dispo-Tool</h3>
        </header>
        <span>{{content}}</span>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {},
        data() {
            return {
                content: '',
            };
        }
    };
</script>
