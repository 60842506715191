import axiosInstance from './api';
import TokenService from './token.service';
import { router } from '../router';

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== '/auth/signin' && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axiosInstance.post('/auth/refreshtoken', {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });
                        const { accessToken } = rs.data;

                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        if(_error.response.status === 403){
                            store.dispatch('auth/logout');
                            store.commit('auth/sessionexpired');
                            router.push({path: 'login', query: {logoutreason: 'sessionExpired'}});
                        } else if (_error.response && _error.response.data && _error.response.data.payload) {
                            return Promise.reject(_error.response.data.payload.message);
                        }
                        return Promise.reject(_error.response);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
