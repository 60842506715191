export default {
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        isAdmin: function () {
            return this.currentUser.roles.includes('ROLE_ADMIN');
        },
        isOfficeUser: function () {
            return this.currentUser.roles.includes('ROLE_OFFICE');
        },
        isOfficeUserOrAdmin: function () {
            return this.currentUser.roles.includes('ROLE_OFFICE') || this.currentUser.roles.includes('ROLE_ADMIN');
        },
        isExternalUser: function () {
            return this.currentUser.roles.includes('ROLE_EXTERNAL');
        },
        isLagerPUser: function () {
            return this.currentUser.roles.includes('ROLE_LAGERP');
        },
    },
};
