import axios from 'axios';
import Vue from 'vue';
import TokenService from './token.service';

export default class AuthService {
    //constructor called in main.js
    constructor(configuration) {
        this.$configuration = configuration;
    }

    login(user) {
        return axios
            .post(this.$configuration.REST_API_BASE_URL + 'auth/signin', {
                username: user.username,
                password: user.password,
            })
            .then((response) => {
                if (response.data.accessToken) {
                    var user = response.data;
                    TokenService.setUser(user);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    install() {
        let thisinstance = this;
        Object.defineProperty(Vue.prototype, '$authService', {
            get() {
                return thisinstance;
            },
        });
    }
}
